import {RadioButtonUnchecked} from "@material-ui/icons"
import "./education.scss"
import { motion, useAnimation } from "framer-motion"
import {useInView} from "react-intersection-observer"
import { useEffect } from "react"

export default function Education() {

    const{ref, inView} = useInView({
        threshold: 0.2
    });
    const animation = useAnimation();
    const animation1 = useAnimation();

    useEffect(() => {
        if(inView){
            animation.start({
                x:0,
                transition:{
                     duration: 1
                }
            })

            animation1.start({
                x:0,
                transition:{
                     duration: 1
                }
            })
        }
        if(!inView){
            animation.start({x:'-100vw', transition:{duration: 1}})
            animation1.start({x:'100vw', transition:{duration: 1}})
        }
        console.log("use effect hook, inView=", inView);
    }, [inView, animation, animation1]);

    return (
        <div className="education" id = "Education">
        <div className="imgWrap">
        <img src="" alt="" />
        </div>

            <div  className="title">
                <h1>Qualification and Achievements</h1>
            </div>
                
            <div ref= {ref} className="contentContainer">
            
                <motion.div className="left"
                    animate={animation}>
                    <h1> Academic Qualification </h1>
                    <p> <RadioButtonUnchecked/> Bachelors of Engineering (Mech) | University of the South Pacific 2018 - 2021</p>
                    <div className="grade1">
                        <p>Undergraduate Studies GPA: 4.43/4.5</p>
                    </div>
                    <p> <RadioButtonUnchecked/> Foundation Science | University of the South Pacific 2017 </p>
                    <div className="grade2">
                        <p>Foundation Studies GPA: 4.45/4.5</p>
                    </div>
                    
                    <h1> Scholarship Awards </h1>
                    <div className="honors">
                        <p> <RadioButtonUnchecked/> Fiji National Toppers Scholarship (NTS) 2018</p>
                        <p2><RadioButtonUnchecked/> Monash University (Australia): International Merit Scholarship</p2>
                        <p1> Value of $50,000 AU: Offered to 31 students [Declined]</p1>

                    </div>
                </motion.div>
                

                <motion.div className="right"
                    animate={animation1}
                >

                    <h1> Honors & Awards </h1>
                    <p> <RadioButtonUnchecked/> The 2021 <p1> Gold Medal and Prize </p1> for the <p1>Vice Chancellor and President’s All-Rounder </p1> 
                    male graduate with the best combined academic performance and record service to the community.</p>
                    <p> <RadioButtonUnchecked/> The 2021 <p1> Gold Medal and Prize </p1> for the most outstanding Bachelor of Engineering (Mechanical) graduate.</p>
                    <p> <RadioButtonUnchecked/> The 2018 <p1> Outstanding Discipline Award </p1> for being the outstanding first year student in Mathematics 
                    at the Faculty of Science, Technology and Environment at the University of the South Pacific.</p>
                    <p> <RadioButtonUnchecked/> The 2017 <p1> Foundation Studies Subject Award</p1> for the most outstanding student in Chemistry, Physics and Mathematics at the University of the South Pacific. </p>
                    <p> <RadioButtonUnchecked/> The <p1>  Minister’s Excellence Award </p1>; National highest scorer for Physics in Year 12 Certificate Examination (FY12CE) 2016</p>
                    <p> <RadioButtonUnchecked/>  Highest scorer and most outstanding student award for Biology, Chemistry and Physics at Jai Narayan College; 
                                                Fiji Year 12 Certificate Examination (FY12CE).</p>
                    <p> <RadioButtonUnchecked/> Most outstanding student and highest scorer at Jai Narayan College for Year 11 Biology, Chemistry, Physics and 
                                                Mathematics 2015 (Internal Annual Examination).</p>
                    <p> <RadioButtonUnchecked/>  Certificate of High Distinction in Australian Mathematics Competition 2014.</p>

                </motion.div>
            </div>
        </div>
    )
}
