import "./sidemenu.scss"

export default function Sidemenu( { menuOpen, setMenuOpen } ) {
    return (
        <div className={"sidemenu " + (menuOpen && "active")} onClick={() => setMenuOpen(false)}>
            <ul>
                <li onClick={() => setMenuOpen(false)}>
                    <a href="#Landing"> Home </a>
                </li>
                <li onClick={() => setMenuOpen(false)}>
                    <a href="#Education"> Education </a>
                </li>
                <li onClick={() => setMenuOpen(false)}>
                    <a href="#Experience"> Experience </a>
                </li>
                <li onClick={() => setMenuOpen(false)}>
                    <a href="#Projectcap"> Projects </a>
                </li>
                <li onClick={() => setMenuOpen(false)}>
                    <a href="#Skills"> Skills </a>
                </li>
            </ul>
        </div>
    )
}
