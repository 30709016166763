import "./project1.scss"

export const Project1 = () => {
    return (
        
        <div className="project1" id = "project1">
            <div className="imgwrap">
                    <img src = "images/vital.jpg" alt="profile"/>
            </div>

            <div className="title">
                <h1> Vital Assistant</h1>

                <div className="title2">
                    <h1>Medical Wearable Device</h1>
                </div>
            </div>
        
            <div className="container">
                
                <div className="left">
                    <div className="box">
                        <div className="box2">
                            <h1>The Vital Assistant is a concept design for an open-sourced medical wearable device. The device is integrated 
                                with medical grade sensors to measure basic physiological parameters, such as, heart rate, SpO2 levels, single 
                                lead Electrocardiogram (ECG) and body temperature. The motivation for this product was to implement it in an 
                                emergency department or an outpatient ward of a hospital where the patients are required to wait before 
                                being attended by a doctor. This device is designed to operate without any user input and can be wirelessly 
                                controlled via a remote desktop/server. The intent of this device was to measure the necessary vitals of a 
                                patient with minimum human contact, hence, protecting the first line workers from any unknown pathogens. 
                                <br></br>
                                <br></br>
                                This project has formed the foundation of a research proposal, by the University of the South Pacific, that aims 
                                to implement such devices in Fijis healthcare system. 
                            </h1>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}
