import "./projectcap.scss"

export const Projectcap = () => {
    return (
        
        <div className="projectcap" id = "Projectcap">
            <div className="imgwrap">
                    <img src = "images/CFD.jpg" alt="profile"/>
                    <div className="imgtop">
                    </div>
                    
            </div>

            <div className="title">
                <h1> Final Year Project</h1>

                <div className="title2">
                    <h1>Cyclone Resilient Building Design and Analysis</h1>
                </div>
            </div>
        
            <div className="container">
                
                <div className="left">
                    <div className="box">
                        <div className="box2">
                            <h1>As part of my final year project thesis, I conducted a research study on affordable cyclone resilient housing 
                                structures in Fiji. This study involved the use of Computational Fluid Dynamics (CFD) to study wind loadings on 
                                roofs through different aerodynamic mitigation devices. The study also explored the possibilities of utilizing 
                                natural ventilation techniques to reduce pressure build up in roof attics. This was a novel approach to cyclone 
                                resilient design, as the air flow was investigated on both sides of the roof. The major outcome of the project was 
                                a novel roof design that improved the aerodynamic performance and provided enhanced natural ventilation 
                                inside of the roof attic. 
                            </h1>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}
