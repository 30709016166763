import "./project2x.scss"

export const Project2x = () => {
    return (
        
        <div className="project2x" id = "project2x">
            <div className="imgwrap">
                    <img src = "images/robocon.jpg" alt="profile"/>
        </div>
        <div className="title">
            <h1> ROBOCON 2021</h1>
            <div className="title2">
                <h1>Design, Fabrication and Programming an Arrow launcher bot</h1>
            </div>
        </div>
        
            <div className="container">
                
                <div className="left">
                    <div className="box">
                        <div className="box2">
                            <h1>Asia-Pacific Robot Contest (ABU Robocon 2021) – Student Engineer at The University of the South Pacific (USP). 
                                My responsibilities included initial CAD designs, fabrication and testing of different robot prototypes. 
                                I was heavily involved in integrating sensors for Arduino and Raspberry pi using various programming languages.  
                                Through this project, I learnt how to control pneumatic cylinders, relays, DC motors, battery configurations etc.
                                Unfortunately, this project was not completed due to Covid-19. 
                            </h1>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}
