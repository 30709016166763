import {RadioButtonUnchecked} from "@material-ui/icons"
import "./experience.scss"
import { motion, useAnimation } from "framer-motion"
import {useInView} from "react-intersection-observer"
import { useEffect } from "react"

export default function Experience() {

    const{ref, inView} = useInView({
        threshold: 0.2
    });
    const animation = useAnimation();
    const animation1 = useAnimation();

    useEffect(() => {
        if(inView){
            animation.start({
                y:0,
                transition:{
                     duration: 1
                }
            })

            animation1.start({
                y:0,
                transition:{
                     duration: 1
                }
            })
        }
        if(!inView){
            animation.start({y:'-100vw', transition:{duration: 1}})
            animation1.start({y:'100vw', transition:{duration: 1}})
        }
        console.log("use effect hook, inView=", inView);
    }, [inView, animation, animation1]);

    return (
        <div className="experience" id = "Experience">
        <div className="imgWrap">
        <img src="" alt="" />
        </div>

            <div  className="title">
                <h1>Professional Experience</h1>
            </div>
                
            <div ref= {ref} className="contentContainer">
            
                <motion.div className="left"
                    animate={animation}>
                    <h1> Work Experience </h1>
                    <p> <RadioButtonUnchecked/> Design and Development Engineer (Student Intern) | Asia-Pacific Robot Contest (ABU Robocon) 2021 </p>
                    <p3>University of the South Pacific </p3>
                    
                    <p><br></br>Under this role, my responsibilities included the initial concept CAD designs, fabrication and testing of different 
                        robot prototypes. I was also involved in integrating sensors with Arduino and Raspberry pi using C, C++ and 
                        Python. I gained hands-on experience in electrical circuitry and machining equipment; such as 3D printing, lathe, 
                        bench drill etc. </p>
                    <br></br>
                    <p> <RadioButtonUnchecked/>  Engineering Attaché </p>
                    <p3>Goodman Fielder International (GFI) Fiji 2019 </p3>
                    <br></br>
                    <p><br></br>During my tenure at GFI, I was an engineering attaché in the Project Management and Maintenance 
                        department. I gained first hand exposure to PMBOK management procedures; reviewing required 
                        documentations in each step of project planning, execution and commission. I also conducted survey and field 
                        study in the production plant as part of the continuous improvement engineering team. I was also tasked at 
                        supervising external contractors on site to ensure that all OHS regulations were followed.  </p>
                    
                </motion.div>
                

                <motion.div className="right"
                    animate={animation1}
                >
                    <h1> Extracurricular Activities </h1>
                    <p> <RadioButtonUnchecked/> President of the <p1> Engineering Student Association (ENSA) </p1>  of the University of the South Pacific 2021.</p>
                    <p> <RadioButtonUnchecked/> Member of the <p1>Engineering Program Advisory Committee (EPAC)</p1> at the University of the South Pacific 2021.</p>
                    <p> <RadioButtonUnchecked/> Activities Representative of the <p1> Engineering Student Association (ENSA) </p1> of the University of the 
                                                South Pacific 2019</p>
                    <p> <RadioButtonUnchecked/> <p1>Japan-Asia Youth Exchange Program</p1> (SAKURA science exchange program): I was selected to represent Fiji in a 
                                                hands-on experiment on conductive polymers by a <p1>Noble Laureate of Chemistry, Prof. Hideki Shirakawa</p1> at the 
                                                University of Shibaura Institute of Technology in Japan.</p>
                    <p> <RadioButtonUnchecked/> Representative of my high school at national competitions: Fiji Team Mathematics Competition (2014-2016), Ministry of Education: Team Physics Competition 2016 
                    and University of the South Pacific: Chemistry Competition 2016.</p>

                </motion.div>
            </div>
        </div>
    )
}
