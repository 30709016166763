import "./navbar.scss"

export default function Navbar({ menuOpen, setMenuOpen}) {


    return (
        <div className={"navbar " + (menuOpen && " active")}>
        <div className="test"></div>
            <div className="wrapper">
                <div className="left">
                <div className="imgWrap">
                    <a href ="#Landing" className={"menuLink "} > <img src = "images/NavLogo.gif" alt="profile"/> </a>
                </div>
                    <a href ="#Landing" className="logo"> Prashant's Portfolio. </a>
                </div>
                <div className="right">
                    <div className="menuItem">
                        <a href ="#Landing" className={"menuLink "} > Home </a>
                        <a href ="#Education" className="menuLink"> Education </a>
                        <a href ="#Experience" className="menuLink"> Experience </a>
                        <a href ="#Projectcap" className="menuLink"> Projects </a>
                        <a href ="#Skills" className="menuLink"> Skills </a>
                    </div>
                    <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
                        <span className="line1"></span>
                        <span className="line2"></span>
                        <span className="line3"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}
