import "./skills.scss"

export default function skills() {
    return (
        <div className="skills" id = "Skills">
            <h1>Skills</h1>
            <h2>Working Knowledge </h2>
            <div className="container1">
                
                <div className="item">
                    <div className="imgContainer">
                        <img src = "images/ansys-logo.jpg" alt="1"/>
                    </div>
                    <div className="caption">
                        <h4> Ansys </h4>
                    </div>  
                </div>
                
                <div className="item">
                    <div className="imgContainer">
                        <img src = "images/solidworks.png" alt="1"/>
                    </div>
                    <div className="caption">
                        <h4> SolidWorks </h4>
                    </div>  
                </div>

                <div className="item">
                    <div className="imgContainer">
                        <img src = "images/strand7.png" alt="1"/>
                    </div>
                    <div className="caption">
                        <h4> Strand7 </h4>
                    </div>  
                </div>

                <div className="item">
                    <div className="imgContainer">
                        <img src = "images/Autodesk.png" alt="1"/>
                    </div>
                    <div className="caption">
                        <h4> Autodesk </h4>
                    </div>  
                </div>

                <div className="item">
                    <div className="imgContainer">
                        <img src = "images/Microsoft_Office.png" alt="1"/>
                    </div>
                    <div className="caption">
                        <h4> Microsoft Office </h4>
                    </div>  
                </div>

                <div className="item">
                    <div className="imgContainer">
                        <img src = "images/flexsim.png" alt="1"/>
                    </div>
                    <div className="caption">
                        <h4> FlexSim </h4>
                    </div>  
                </div>

                <div className="item">
                    <div className="imgContainer">
                        <img src = "images/C++.png" alt="1"/>
                    </div>
                    <div className="caption">
                        <h4> C++ </h4>
                    </div>  
                </div>

                <div className="item">
                    <div className="imgContainer">
                        <img src = "images/Python_(programming_language).png" alt="1"/>
                    </div>
                    <div className="caption">
                        <h4> Python </h4>
                    </div>  
                </div>
                

            </div>

            <div className="t2">
                
            <h3>Interested to further explore</h3>
            </div>

            <div className="container2">
                <div className="item">
                    <div className="imgContainer">
                        <img src = "images/FEA.png" alt="1"/>
                    </div>
                    <div className="caption">
                        <h4> FEA Analysis </h4>
                    </div>  
                </div>
                <div className="item">
                    <div className="imgContainer">
                        <img src = "images/renewable.png" alt="1"/>
                    </div>
                    <div className="caption">
                        <h4> Renewable Energy </h4>
                    </div>  
                </div>

                <div className="item">
                    <div className="imgContainer">
                        <img src = "images/med.png" alt="1"/>
                    </div>
                    <div className="caption">
                        <h4> Medical IoT </h4>
                    </div>  
                </div>

                <div className="item">
                    <div className="imgContainer">
                        <img src = "images/micro.png" alt="1"/>
                    </div>
                    <div className="caption">
                        <h4> MicroFluids </h4>
                    </div>  
                </div>

                <div className="item">
                    <div className="imgContainer">
                        <img src = "images/React_(web_framework).png" alt="1"/>
                    </div>
                    <div className="caption">
                        <h4> React js </h4>
                    </div>  
                </div>

                <div className="item">
                    <div className="imgContainer">
                        <img src = "images/dl.png" alt="1"/>
                    </div>
                    <div className="caption">
                        <h4> Machine learning</h4>
                    </div>  
                </div>

                
            </div>
        </div>
    )
}
