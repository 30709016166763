import "./app.scss";
import Navbar from "./components/nav/Navbar";
import Sidemenu from "./components/Sidemenu/Sidemenu";
import Landing from "./components/landing/Landing";
import Education from "./components/education/Education";
import Experience from "./components/experience/Experience";
import { Projectcap}  from "./components/projectcap/projectcap";
import  {Project1} from "./components/project1/project1";
import { Project3 } from "./components/project3/project3";
import Skills from "./components/skills/Skills";
import Footer from "./components/footer/footer";
import { useState } from "react";
import Project4 from "./components/project4/project4";
import { Project2x } from "./components/project2x/project2x";


function App() {
  const [menuOpen, setMenuOpen] = useState (false);

  return (
    
    <div className="app">
      <Navbar menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
      <Sidemenu menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>

      <div className="sections">
      <div className="section1">
      <Landing/>
      </div>
      <div className="fitContent">
        <Education/>
      </div>
      <div className="fitcontentex">
        <Experience/>
      </div>
      <div className="section2">
      <Projectcap/>
      <Project1/>
      <Project2x/>
      <Project3/>
      </div>
      <Project4/>
        <div className="section2">
        <Skills/>
        </div>
        <div className="fitContent1">
        <Footer/>
        </div>
        </div>
    </div>
  );
}

export default App;
