import {RadioButtonUnchecked} from "@material-ui/icons"
import "./project4.scss"
import { motion, useAnimation } from "framer-motion"
import {useInView} from "react-intersection-observer"
import { useEffect } from "react"

export default function Project4() {

    const{ref, inView} = useInView({
        threshold: 0.2
    });
    const animation = useAnimation();
    const animation1 = useAnimation();

    useEffect(() => {
        if(inView){
            animation.start({
                x:0,
                transition:{
                     duration: 1
                }
            })

            animation1.start({
                x:0,
                transition:{
                     duration: 1
                }
            })
        }
        if(!inView){
            animation.start({x:'-100vw', transition:{duration: 1}})
            animation1.start({x:'100vw', transition:{duration: 1}})
        }
        console.log("use effect hook, inView=", inView);
    }, [inView, animation, animation1]);

    return (
        <div className="project4" id = "project4">
        

            <div  className="title">
                <h1>Project Experiences</h1>
            </div>
                
            <div ref= {ref} className="contentContainer">
            
                <motion.div className="left"
                    animate={animation}
                >
                    <div className="imgWrap">
                        <img src="images/12.gif" alt="dna" />
                    </div>
                    
                </motion.div>
                

                <motion.div className="right"
                    animate={animation1}
                >
                    <p> <RadioButtonUnchecked/> Prashant's Portfolio WebDevelopment: using React js and CSS programming (self taught)</p>
                    <p> <RadioButtonUnchecked/> An ISM based Approach for the Barrier Analysis of Telecommuting in a Post Pandemic Environment 2021</p>
                    <p> <RadioButtonUnchecked/> Research Review: Optimization of Concrete Mix Design in Fiji using Taguchi Approach 2021</p>
                    <p> <RadioButtonUnchecked/> 10 kW Wind turbine blade design and optimization using HARP_Opt code 2021</p>
                    <p> <RadioButtonUnchecked/> Metrology and Instrumentation: Programming an IR prximity sensor using LabView</p>
                    <p> <RadioButtonUnchecked/> Geometric modification and Xfoil simulation of an S1223 airfoil</p>
                    <p> <RadioButtonUnchecked/> Computational Fluid Dynamic (CFD) analysis on bluff bodies- Cylinder</p>
                    <p> <RadioButtonUnchecked/> Design of a Passive Water Pump for water wells around Fiji</p>
                    <p> <RadioButtonUnchecked/> Design of a Photovoltaic Standalone and Hybrid system</p>
                    <p> <RadioButtonUnchecked/> Design and development of a computer algorithm to analyse a 2 pivot non-composite beam
                        with externally applied forces (coded in C++)</p>
                    <p> <RadioButtonUnchecked/> Design of a Passive Solar Desalination Unit</p>

                </motion.div>
            </div>
        </div>
    )
}
