import React from 'react'
import {FaLinkedin, FaGithub, FaFacebook} from 'react-icons/fa'
import "./footer.scss"

export default function Footer () {
    return (
            <div className="footer" id="footer">
                <div className="container">
                    <div className="head">
                    <h2> Contact Me</h2>
                    </div>
                    
                    <div className="top">

                    <div className="linkedin">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/prashant-chand-fiji/">
                            <FaLinkedin size="3em" color="#0077B5"/>
                        </a>
                    </div>
            
                    <div className="Facebook">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/vishalfj/">
                            <FaFacebook size="3em" color= "#3b5998 "/>
                        </a>
                    </div>

                    <div className="git">
                        <a target="_blank" rel="noopener noreferrer" href="https://github.com/vcPrashant">
                            <FaGithub size="3em" color= "white" className="git"/>
                        </a>
                    </div>

                    </div>

                    <div className="bottom">
                        <h3> Copyright © 2022 Prashant Chand. All Rights Reserved.</h3>
                    </div>
                </div>
                
            </div>
    )
}

