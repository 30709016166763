import "./project3.scss"

export const Project3 = () => {
    return (
        
        <div className="project3" id = "project3">
            <div className="imgwrap">
                    <img src = "images/wheelchair.jpg" alt="profile"/>
        </div>
        <div className="title">
            <h1> Passive Wheelchair</h1>
            <div className="title2">
                <h1>CAD Design and structural analysis</h1>
            </div>
        </div>
        
            <div className="container">
                
                <div className="left">
                    <div className="box">
                        <div className="box2">
                            <h1>In developing countries, such as Fiji, wheelchairs are often imported at a high cost. This projects aim was to 
                                design an affordable passive wheelchair that can be manufactured locally with the available resources. This 
                                wheelchair is designed in accordance to the WHO and ISO 7176 guidelines, The trademark of this passive 
                                wheelchair is the adjustable features which allow the user to change the size along the x, y and z axis, to meet 
                                their personal preference. This is particularly useful for children, as the wheelchair can accommodate ones 
                                growing body. The idea of using push pins that allows the adjustment settings was inspired from walking canes 
                                and crutches. The proposed wheelchair will successfully allow physically impaired individuals to practice their 
                                rights in participating in everyday activities, and live a safe and comfortable life.
                                <br></br><br></br>
                                This project was inspired by the Hilton Special School in Fiji - a special school for physically impaired 
                                individuals. 
                            </h1>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}
