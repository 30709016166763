import "./landing.scss"
import{KeyboardArrowDown} from "@material-ui/icons"
import { init } from 'ityped' 
import { useRef, useEffect } from "react"
import video1 from "../../videos/Hero.mp4"
import {FaLinkedin, FaGithub} from 'react-icons/fa'



export default function Landing() {

    const textRef = useRef();

    useEffect(()=>{
        init(textRef.current, {

            showCursor: true,
            backDelay: 1000,
            backSpeed: 50,
            strings: [" Design Engineer","Project Engineer","Researcher"]
        }); 
        
    },[])

    return (
        <div className="landing" id = "Landing">

        <div className="videoContainer">
            <video autoPlay loop muted src={video1} type='2/mp4' />
        </div>
        
        
        <div className="container">
        <div className="icon2">
            <div className="linkedin">
                <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/prashant-chand-fiji/">
                    <FaLinkedin size="3em" color="#fff" />
                </a>
            </div>
            
            
            <div className="git">
                <a target="_blank" rel="noopener noreferrer" href="https://github.com/vcPrashant">
                    <FaGithub size="3em" color= "white" className="git"/>
                </a>
            </div>
            

        </div>
        <div className="left">
                <div className="wrapper">
                    <div className="contact">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/prashant-chand-fiji/">
                        <FaLinkedin size="2em" color="#fff" className="linkedin2" />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://github.com/vcPrashant">
                        <FaGithub size="2em" color= "white" className="git2"/>
                    </a>
                    </div>

                    <h2> Hi, I'am </h2>
                    <h1> Prashant Chand</h1>
                    <h3> B.E. Mechanical Engineering  <span ref={textRef}> </span></h3>
                    <div className="button">
                    <a href="#Education">
                    <KeyboardArrowDown className="icon1"/>
                    </a>
                    
                </div>
                </div>
                
            </div>
            <div className="right">
                <div className="imgContainer">
                    <img src = "images/Prashant2.jpg" alt="profile"/>
                </div>
            </div>
        </div>
            
        </div>
    )
}
